import React, { useEffect,useState } from 'react'
import { CardBasic, CardHeaderWithActions,MwButton,MwInputText,MwSelector, MwSpinner, MwSpinnerButton, NoDataFound } from '../components/ui';
import { useCurrent, useFetch, usePost } from '../hooks';
import RatingList from './rating/RatingList';

const Reports = () => {
    const [mode,setMode] = useState('Listing');

    const [serviceSelected,setServiceSelected] = useState();
    const [startDate,setStartDate] = useState();
    const [endDate,setEndDate] = useState();

    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const {data:servicesResult,loading:servicesLoading,refreshHandler} = useFetch(`${process.env.REACT_APP_RATING_API_BASE_URL}/services/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);

    const {data:postResult,loading:postLoading,postData} = usePost();


    useEffect(() =>{
        if(servicesResult){
            let _rand = Math.round(Math.random() * (servicesResult?.data?.length - 0) + 0);
            if(_rand >= servicesResult?.data?.length ){
                _rand = _rand -1;
            }
            const _selected = servicesResult?.data[_rand]
            setServiceSelected({label:_selected.serviceName, value:_selected});
            console.log('servicesResult => ',servicesResult);
        }
    },[servicesResult]);

    useEffect(() =>{
        if(serviceSelected){
        }
        console.log('serviceSelected',serviceSelected)
    },[serviceSelected]);

    const submitReport = () =>{
        const _url = `${process.env.REACT_APP_RATING_API_BASE_URL}/filter/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${serviceSelected?.value?._id}`;
        if(startDate && endDate ){
            const _data ={
                dateStart:new Date(startDate),
                dateEnd: new Date(endDate),
            }
            postData(_url,_data);
            refreshHandler();
        }
    }

    return (
        <>
            <div className='h-full flex flex-col '>
                <CardHeaderWithActions title={`التقارير`}>

                </CardHeaderWithActions>
                <CardBasic>
                    <div className='w-full p-2'>
                        <div className='w-full flex gap-5 justify-items-between  '>
                            <div className='flex-1 '>
                                <MwSelector
                                    label={'أختار الخدمة : '}
                                    initalValue = {serviceSelected?.label || 'أختار الخدمة....' }
                                    dataType={'labelValue'}
                                    withAddNew ={false}
                                    setSelectedItem={setServiceSelected}
                                    selectedItem={serviceSelected}
                                    _data={servicesResult?.data?.map(service => ({
                                        label:service.serviceName,
                                        value:service,
                                    }))}
                                    />
                            </div>
                            <div className='flex-1'>
                                <MwInputText 
                                    label ='بداية الفترة :'
                                    value = {startDate}
                                    onChange ={ (e) => setStartDate(e.target.value)}
                                    inputType='date' />
                            </div>
                            <div className='flex-1'>
                                <MwInputText 
                                    label ='نهاية الفترة :'
                                    value = {endDate}
                                    onChange = { (e) => setEndDate(e.target.value)}
                                    inputType='date' />
                            </div>
                        </div>
                        <div className='pt-5 flex flex-row-reverse'>
                            <MwButton onClick={() => submitReport()} type='secondary'>أعداد التقرير</MwButton>
                        </div>
                    </div>
                </CardBasic>
                { postLoading && <MwSpinner /> }
                { postResult && 
                    <CardBasic className={'p-3 my-5'}>
                        { postResult?.data?.length === 0 ? <NoDataFound msg={'لا توجد نتائج'} /> : <RatingList loading={postLoading} data={postResult} />}
                    </CardBasic>
                }

            </div>
        </>
    )
}

export default Reports